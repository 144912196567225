import { ICoord } from "../../../@types/GoogleMaps/ICoord";
import { BatchIcon } from "../BatchIcon/Index";

export type ICustomBatch = {
  initials: string;
  coords: ICoord;
  id: string;
  farmId: string;
  areaId: string;
  visible: boolean;
  currentMicroArea?: number;
};

type BatchesIconsProps = {
  batches: ICustomBatches;
};

interface ICustomBatches extends Array<ICustomBatch> { }

export function BatchesIcons({ batches }: BatchesIconsProps) {

  return (
    <>
      {batches
        .filter((f) => f.visible)
        .map((batch, key) => (
          <BatchIcon key={key} coords={batch.coords} />
        ))}
    </>
  );
}
