import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown } from "../../EditOnboarding/components/Select";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import { LeafletStaticMap } from "../../Leaflet/LeafletStaticMap/Index";
import { IOptionProps, IOptionsProps } from "../../../@types/reactSelect/IOption";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";
import {
    FlyToBounds,
    FlyToBoundsRefProps,
} from "../../Leaflet/FlyToBounds/Index";
import Constants from "../../../constants/Index";
import axios from "../../../services/axios"
import { Polygon } from "react-leaflet";
import * as yup from 'yup';
import "./styles.css";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import { useFilter } from "../../../hooks/useFilter";
import { ICoords } from "../../../@types/GoogleMaps/ICoord";

export default function CreatePastureManagement() {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const { defaultValue, setOptionForm } = useEditOnboarding();
    const { setSystemManager } = useFilter();

    const frequencyOptions = [
        { label: "Semanal", value: 1, info: "WEEKLY" },
        { label: "Bisemanal", value: 2, info: "BIWEEKLY" },
        { label: "Mensal", value: 3, info: "MONTHLY" },
    ]

    const pastureManagementSchema = yup.object()
        .shape({
            farm: yup
                .object()
                .nullable()
                .required('Fazenda é necessária.'),
            area: yup
                .object()
                .nullable()
                .required('Retiro é necessário.'),
            microAreas: yup
                .array()
                .nullable()
                .required('Pastos é necessário.')
                .min(1, "Pastos é necessário."),
            type: yup
                .object()
                .nullable()
                .required('Tipo de pastejo é necessário.'),
            monitor: yup
                .object()
                .nullable()
                .when(['type'], {
                    is: (type: IOptionProps) =>
                        (type?.label != "Sem monitoramento"),
                    then: yup.object().nullable().required('Tipo de monitoramento é necessário.'),
                }),
            heightInput: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (monitor: IOptionProps) =>
                        monitor?.label == "Altura",
                    then: yup.number().test('', 'Altura de entrada é necessária.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            heightOutput: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (monitor: IOptionProps) =>
                        monitor?.label == "Altura",
                    then: yup.number().test('', 'Campo obrigatório não informado.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            occupationDays: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (monitor: IOptionProps) =>
                        monitor?.label == "Dias",
                    then: yup.number().test('', 'Campo obrigatório não informado.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            restDays: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (monitor: IOptionProps) =>
                        monitor?.label == "Dias",
                    then: yup.number().test('', 'Campo obrigatório não informado.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            rateIn: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (monitor: IOptionProps) =>
                        monitor?.label == "Nota",
                    then: yup.number().test('', 'Campo obrigatório não informado.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            rateOut: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (monitor: IOptionProps) =>
                        monitor?.label == "Nota",
                    then: yup.number().test('', 'Campo obrigatório não informado.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            range: yup
                .number()
                .when(['type'], {
                    is: (type: IOptionProps) =>
                        type?.label == "Em faixa",
                    then: yup.number().test('', 'Campo obrigatório não informado.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            frequency: yup
                .object()
                .nullable()
                .when(['type'], {
                    is: (type: IOptionProps, monitor: IOptionProps) =>
                        type?.label != "Sem monitoramento" &&
                        (monitor?.label == "Altura" || monitor?.label == "Nota"),
                    then: yup.object().nullable().required('Tipo de frequência é necessário.'),
                }),
        })

    const [optionsTypeManagement, setOptionsTypeManagement] = useState<IOptionsProps>([]);
    const [optionsTypeMonitor, setOptionsTypeMonitor] = useState<IOptionsProps>([]);
    const [selectedTypeMonitor, setSelectedTypeMonitor] = useState<any>();
    const [optionsMicroAreas, setOptionsMicroAreas] = useState<IOptionsProps>([]);
    const [microAreaRoute, setMicroAreaRoute] = useState<any>();
    const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
    const [heightOutput, setHeightOutput] = useState(0);
    const [rateOutput, setRateOutput] = useState(0);

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
        trigger
    } = useForm({
        defaultValues: defaultValue,
        resolver: yupResolver(pastureManagementSchema),
    });

    useEffect(() => {
        axios
            .get("micro-area-management/types")
            .then((response) => {
                const types: IOptionsProps = response.data.map((type: any) => ({
                    label: type.name,
                    value: type.id,
                    info: type.monitoring_types
                }))
                setOptionsTypeManagement(types)
            })

        axios
            .get("/farms/user")
            .then(({ data }) => {
                setListFarms(
                    data.map((farm: any) => ({
                        label: farm.name,
                        value: farm.id,
                        info: farm,
                    }))
                );
            });

        axios
            .get("/areas/user")
            .then(({ data }) => {
                setListAreas(
                    data.map((area: any) => ({
                        label: area.name,
                        value: area.id,
                        info: area,
                    }))
                );
            });

        axios
            .get("/micro-areas")
            .then(({ data }) => {
                const available: IOptionsProps = data.map((microArea: any) => ({
                    label: `${microArea.farm.initials}-${microArea.area.initials}-${microArea.initials}`,
                    value: microArea.id,
                    info: microArea,
                }));
                setOptionsMicroAreas(available);
            });
    }, []);

    const [heightInputError, setHeightInputError] = useState("");
    const [heightOutputError, setHeightOutputError] = useState("");
    const [occupationDaysError, setOccupationDaysError] = useState("");
    const [restDaysError, setRestDaysError] = useState("");
    const [rangeError, setRangeError] = useState("");
    const [executionPerDayError, setExecutionPerDayError] = useState("");
    const [rateInError, setRateInError] = useState("");
    const [rateOutError, setRateOutError] = useState("");

    function handleChange(name: string) {
        if (showErrorMessage) {
            trigger(name)
        }
    }

    const diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const [selectedDays, setSelectedDays] = useState<boolean[]>(new Array(diasSemana.length).fill(false));
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [disabledDays, setDisabledDays] = useState<number[]>([]);

    function toggleDay(index: number) {
        setSelectedDays(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    }

    const frequency = watch("frequency")
    const typeManagement = watch("type")
    const microAreas = watch("microAreas")

    useEffect(() => {
        setDisabledDays(selectedDays.map((day, index) => !day ? index : 7))
        if (startDate?.getDay() && !selectedDays[startDate?.getDay()]) {
            setStartDate(undefined)
        }
    }, [selectedDays]);

    useEffect(() => {
        if (frequency?.value == 2) {
            setSelectedDays(new Array(diasSemana.length).fill(false));
        } else if (frequency?.value == 3) {
            setDisabledDays([7])
        }
    }, [frequency]);

    useEffect(() => {
        if (selectedTypeMonitor?.label == "Dias" || typeManagement?.label == "Em faixa") {
            setDisabledDays([7])
        }
    }, [selectedTypeMonitor, typeManagement]);


    function onSubmit(data: any) {
        let bodyPost = {} as any;

        switch (data?.type?.label) {
            case "Sem monitoramento":
                bodyPost = {
                    micro_area_ids: data?.microAreas.map((microArea: IOptionProps) => Number(microArea.value)),
                    management_type_id: Number(data?.type?.value),
                }
                break;
            default:
                let requirements = {
                    requirement_in: null,
                    requirement_out: null
                } as any;
                switch (data?.monitor?.label) {
                    case "Altura":
                        requirements = {
                            requirement_in: data?.heightInput,
                            requirement_out: data?.heightOutput
                        }
                        break;
                    case "Nota":
                        requirements = {
                            requirement_in: data?.rateIn,
                            requirement_out: data?.rateOut
                        }
                        break;
                    case "Dias":
                        requirements = {
                            requirement_in: data?.occupationDays,
                            requirement_out: data?.restDays
                        }
                        break;
                    case "Faixa":
                        requirements = {
                            requirement_in: data?.range,
                            requirement_out: null
                        }
                        break;
                    default:
                        requirements = {
                            requirement_in: null,
                            requirement_out: null
                        }
                        break;
                }
                bodyPost = {
                    micro_area_ids: data?.microAreas.map((microArea: IOptionProps) => Number(microArea.value)),
                    management_type_id: Number(data?.type?.value),
                    monitoring_type_id: Number(data?.monitor?.value),
                    ...requirements,
                    frequency: data?.frequency?.info,
                    days_of_week: selectedDays?.map((day, index) => day ? (index === 0 ? 7 : index) : -1).filter((day) => day != -1),
                    execution_per_day: data?.executionPerDay || null,
                    start_date: startDate ? format(startDate, "yyyy-MM-dd") : undefined,
                }
                if (defaultValue && defaultValue?.id) {
                    bodyPost = {
                        ...bodyPost,
                        management_group_id: defaultValue.id
                    }
                }
                break;
        }

        axios.post("/micro-area-management", bodyPost)
            .then(() => {
                setOptionForm(undefined)
                setSystemManager(null)
            })
    }

    const [requirementIn, setRequirementIn] = useState(0);
    const [requirementOut, setRequirementOut] = useState(0);
    const [executionPerDay, setExecutionPerDay] = useState(0);

    useEffect(() => {
        if (defaultValue && optionsTypeManagement && optionsTypeManagement.length > 0) {
            setOptionsTypeMonitor(optionsTypeManagement.filter(el => el.value == defaultValue?.type?.value)[0].info
                .map((el: any) => ({
                    label: el?.name,
                    value: el?.id
                }))
            )
            setSelectedTypeMonitor({
                label: defaultValue.monitor.label,
                value: defaultValue.monitor.value
            })
            setRequirementIn(defaultValue.requirement_in)
            setRequirementOut(defaultValue.requirement_out)
            setExecutionPerDay(defaultValue.execution_per_day)
            setSelectedDays(diasSemana.map((_, index) => defaultValue.days_of_week.map((day: any) => day - 1)))
            setMicroAreaRoute(defaultValue.microAreas.map((el: any) => el.info))
            setValue("frequency", frequencyOptions.filter(fr => fr.info == defaultValue.frequency)[0])
            setValue("type", defaultValue.type)
            const [year, month, day] = defaultValue.start_date.split("-");
            setTimeout(() => {
                setStartDate(new Date(Number(year), Number(month) - 1, Number(day)))
            }, 200);
            setCurrentFarm(defaultValue.farm)
            setValue("farm", {
                label: defaultValue.farm.name,
                value: defaultValue.farm.id
            })
            setCurrentArea(defaultValue.area)
            setValue("area", {
                label: defaultValue.area.name,
                value: defaultValue.area.id
            })
        }
    }, [defaultValue, optionsTypeManagement]);

    const [listFarms, setListFarms] = useState<IOptionsProps>();
    const [listAreas, setListAreas] = useState<IOptionsProps>();
    const [fitCoords, setFitCoords] = useState<ICoords>([]);
    const [currentFarm, setCurrentFarm] = useState<any>();
    const [currentArea, setCurrentArea] = useState<any>();

    useEffect(() => {
        if (currentFarm && currentFarm.map_coords) {
            setFitCoords(currentFarm.map_coords)
        }
    }, [currentFarm]);

    useEffect(() => {
        if (currentArea && currentArea.map_coords) {
            setFitCoords(currentArea.map_coords)
        }
    }, [currentArea]);

    return (
        <>
            <div className="body__formPastureManagement">
                <form onSubmit={() => handleSubmit(onSubmit)}>
                    <div className="firstColumn columnPastureManagement">
                        <div>
                            <span className="labelCreateEvaluator">Fazenda</span>
                            <Dropdown
                                name={"farm"}
                                control={control}
                                placeholderText={"Selecionar fazenda..."}
                                options={listFarms}
                                getSelectedData={(option: any) => {
                                    setCurrentFarm(option.info);
                                    setValue("area", null)
                                    setCurrentArea(undefined)
                                    setValue("microAreas", null)
                                    setMicroAreaRoute([])
                                }}
                                closeMenuOnSelect={true}
                                noOptionsMessage={() => (
                                    <span>Nenhuma fazenda disponível</span>
                                )}
                            />
                        </div>

                        <div>
                            <span className="labelCreateEvaluator">Retiro</span>
                            <Dropdown
                                name={"area"}
                                control={control}
                                placeholderText={"Selecionar retiro..."}
                                options={
                                    listAreas
                                        ? listAreas.filter(
                                            (area: any) => area?.info?.farm?.id == currentFarm?.id
                                        )
                                        : []
                                }
                                getSelectedData={(option: any) => {
                                    setCurrentArea(option.info);
                                    setValue("microAreas", null)
                                    setMicroAreaRoute([])
                                }}
                                closeMenuOnSelect={true}
                                noOptionsMessage={() => (
                                    <span>Nenhum retiro disponível</span>
                                )}
                            />
                        </div>

                        <div>
                            <span className="labelCreateEvaluator">Pastos</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    options={
                                        (
                                            microAreas && microAreas.length > 0 ? optionsMicroAreas.filter(ma1 => !microAreas.map((ma2: any) => ma2.value).includes(ma1.value)) : optionsMicroAreas
                                        ).filter(
                                            (micro: any) => micro?.info?.area_id == currentArea?.id
                                        )
                                    }
                                    placeholderText="Selecionar pastos..."
                                    getSelectedData={(el: any) => {
                                        setMicroAreaRoute(el.map((microArea: IOptionProps) => microArea.info))
                                    }}
                                    name={"microAreas"}
                                    control={control}
                                    noOptionsMessage={() => <span>Nenhum pasto disponível</span>}
                                    isMulti
                                />
                            </div>
                        </div>

                        <div>
                            <span className="labelCreateEvaluator">Tipo de Pastejo</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    options={optionsTypeManagement}
                                    placeholderText="Selecionar o tipo de pastejo..."
                                    getSelectedData={(el) => {
                                        setOptionsTypeMonitor(el.info.map((type: any) => ({
                                            label: type.name,
                                            value: type.id,
                                        })))
                                        setValue("monitor", null)
                                        setSelectedTypeMonitor(undefined)
                                        setSelectedDays(new Array(diasSemana.length).fill(false));
                                        if (el.label == "Em faixa") {
                                            setValue("monitor", {
                                                label: el.info[0].name,
                                                value: el.info[0].id,
                                            })
                                        }
                                        setRequirementIn(0)
                                        setRequirementOut(0)
                                        setExecutionPerDay(0)
                                    }}
                                    name={"type"}
                                    control={control}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => <span>Nenhum tipo de pastejo disponível</span>}
                                />
                            </div>
                        </div>

                        {optionsTypeMonitor && optionsTypeMonitor.length > 0 ?
                            <>
                                {typeManagement && typeManagement.label != "Em faixa" &&
                                    <div>
                                        <span className="labelCreateEvaluator">Tipo de Monitoramento</span>
                                        <div className="filters-dropdown filters-dropdown__full-width">
                                            <Dropdown
                                                options={optionsTypeMonitor}
                                                placeholderText="Selecionar o tipo de monitoramento..."
                                                getSelectedData={(el) => {
                                                    setSelectedTypeMonitor(el)
                                                    if (el.label == "Dias") {
                                                        setValue("frequency", null)
                                                        setSelectedDays(new Array(diasSemana.length).fill(false));
                                                    }
                                                }}
                                                name={"monitor"}
                                                control={control}
                                                closeMenuOnSelect={true}
                                                noOptionsMessage={() => <span>Nenhum tipo de monitoramento disponível</span>}
                                            />
                                        </div>
                                    </div>
                                }

                                {selectedTypeMonitor && selectedTypeMonitor.label == "Altura" &&
                                    <>
                                        <div>
                                            <span className="labelCreateEvaluator">Altura da entrada</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("heightInput", value)

                                                    if (value != 0) {
                                                        setHeightInputError("")
                                                    } else setHeightInputError(`Altura de entrada é necessária.`)
                                                    handleChange("heightInput")

                                                    if (value < watch("heightOutput")) {
                                                        setValue("heightOutput", value)
                                                        setHeightOutput(value)
                                                    }
                                                }}
                                                defaultValue={requirementIn}
                                                name={"heightInput"}
                                                control={control}
                                                label={"cm"}
                                                errorText={showErrorMessage ? heightInputError : ""}
                                                max={999}
                                            />
                                        </div>

                                        <div>
                                            <span className="labelCreateEvaluator">Altura da saída</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    if (heightOutput != value)
                                                        setHeightOutput(value)
                                                    setValue("heightOutput", value)

                                                    if (value != 0) {
                                                        setHeightOutputError("")
                                                    } else setHeightOutputError(`Altura de saída é necessária.`)
                                                    handleChange("heightOutput")
                                                }}
                                                defaultValue={requirementOut}
                                                name={"heightOutput"}
                                                control={control}
                                                max={watch("heightInput") || 999}
                                                label={"cm"}
                                                errorText={showErrorMessage ? heightOutputError : ""}
                                                value={heightOutput}
                                            />
                                        </div>
                                    </>
                                }

                                {selectedTypeMonitor && selectedTypeMonitor.label == "Dias" &&
                                    <>
                                        <div>
                                            <span className="labelCreateEvaluator">Dias de ocupação</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("occupationDays", value)
                                                    if (value != 0) {
                                                        setOccupationDaysError("")
                                                    }
                                                    else setOccupationDaysError(`Dias de ocupação é necessário.`)
                                                    handleChange("occupationDays")
                                                }}
                                                defaultValue={requirementIn}
                                                name={"occupationDays"}
                                                control={control}
                                                max={999}
                                                label={"dias"}
                                                errorText={showErrorMessage ? occupationDaysError : ""}
                                            />
                                        </div>

                                        <div>
                                            <span className="labelCreateEvaluator">Dias de descanso</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("restDays", value)
                                                    if (value != 0) {
                                                        setRestDaysError("")
                                                    }
                                                    else setRestDaysError(`Dias de descanso é necessário.`)
                                                    handleChange("restDays")
                                                }}
                                                defaultValue={requirementOut}
                                                name={"restDays"}
                                                control={control}
                                                max={999}
                                                label={"dias"}
                                                errorText={showErrorMessage ? restDaysError : ""}
                                            />
                                        </div>
                                    </>
                                }

                                {selectedTypeMonitor && selectedTypeMonitor.label == "Nota" &&
                                    <>
                                        <div>
                                            <span className="labelCreateEvaluator">Nota de entrada do pasto (0 a 10)</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("rateIn", value)

                                                    if (value != 0) {
                                                        setRateInError("")
                                                    } else setRateInError(`Nota do pasto é necessário.`)
                                                    handleChange("rateIn")

                                                    if (value < watch("rateOut")) {
                                                        setValue("rateOut", value)
                                                        setRateOutput(value)
                                                    }
                                                }}
                                                defaultValue={requirementIn}
                                                name={"rateIn"}
                                                control={control}
                                                max={10}
                                                errorText={showErrorMessage ? rateInError : ""}
                                            />
                                        </div>

                                        <div>
                                            <span className="labelCreateEvaluator">Nota de saída do pasto (0 a 10)</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    if (rateOutput != value)
                                                        setRateOutput(value)
                                                    setValue("rateOut", value)

                                                    if (value != 0) {
                                                        setRateOutError("")
                                                    } else setRateOutError(`Nota do pasto é necessário.`)
                                                    handleChange("rateOut")
                                                }}
                                                defaultValue={requirementOut}
                                                name={"rateOut"}
                                                control={control}
                                                max={watch("rateIn") || 10}
                                                errorText={showErrorMessage ? rateOutError : ""}
                                                value={rateOutput}
                                            />
                                        </div>
                                    </>
                                }

                                {typeManagement && typeManagement.label == "Em faixa" &&
                                    <>
                                        <div>
                                            <span className="labelCreateEvaluator">Quantidade em faixas</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("range", value)
                                                    if (value != 0) {
                                                        setRangeError("")
                                                    }
                                                    else setRangeError(`Quantidade é necessário.`)
                                                    handleChange("range")
                                                }}
                                                defaultValue={requirementIn}
                                                name={"range"}
                                                control={control}
                                                max={999}
                                                errorText={showErrorMessage ? rangeError : ""}
                                            />
                                        </div>

                                        <div>
                                            <span className="labelCreateEvaluator">Quantidade de execuções por dia</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("executionPerDay", value)
                                                    if (value != 0) {
                                                        setExecutionPerDayError("")
                                                    }
                                                    else setExecutionPerDayError(`Quantidade de execuções é necessário.`)
                                                    handleChange("executionPerDay")
                                                }}
                                                defaultValue={executionPerDay}
                                                name={"executionPerDay"}
                                                control={control}
                                                max={999}
                                                errorText={showErrorMessage ? executionPerDayError : ""}
                                            />
                                        </div>
                                    </>
                                }

                                {selectedTypeMonitor && (selectedTypeMonitor.label == "Altura" || selectedTypeMonitor.label == "Nota") &&
                                    <div>
                                        <span className="labelCreateEvaluator">Frequência de monitoramento</span>
                                        <Dropdown
                                            name={"frequency"}
                                            control={control}
                                            options={frequencyOptions}
                                            placeholderText="Selecionar frequência..."
                                            closeMenuOnSelect={true}
                                        />
                                    </div>
                                }
                                <span className="labelCreateEvaluator">
                                    {typeManagement?.label != "Em faixa" && frequency?.label == "Semanal" ? "Selecione os dias e a data da primeira tarefa" : ""}
                                    {typeManagement?.label != "Em faixa" && frequency?.label == "Bisemanal" ? "Selecione o dia e a data da primeira tarefa" : ""}
                                    {typeManagement?.label == "Em faixa" || frequency?.label == "Mensal" || selectedTypeMonitor?.label == "Dias" ? "Selecione a data da primeira tarefa" : ""}
                                </span>

                                {typeManagement?.label != "Em faixa" &&
                                    ((frequency?.label == "Semanal") || (frequency?.label == "Bisemanal")) &&
                                    <div style={{
                                        display: "flex",
                                        gap: 10,
                                    }}>
                                        {diasSemana.map((dia, index) =>
                                            <div className={`frequencyDay__formPastureManagement${selectedDays[index] ? "--selected" : ""}`}
                                                onClick={() => {
                                                    if (frequency?.label == "Bisemanal") {
                                                        setSelectedDays(_ => {
                                                            const newState = new Array(diasSemana.length).fill(false);
                                                            newState[index] = true;
                                                            return newState;
                                                        });
                                                    } else {
                                                        toggleDay(index);
                                                    }
                                                }}
                                            >
                                                <span>{dia}</span>
                                            </div>)
                                        }
                                    </div>
                                }

                                <div className="daySelect__formPastureManagement">
                                    <DayPicker
                                        mode="single"
                                        fromDate={new Date()}
                                        disabled={{ dayOfWeek: disabledDays }}
                                        selected={startDate}
                                        onDayClick={(day) => {
                                            setStartDate(day)
                                        }}
                                        showOutsideDays
                                        fixedWeeks
                                    />

                                    {!startDate &&
                                        <small className="textInput--small-error animation__fadeIn">
                                            Selecione uma data inicial.
                                        </small>
                                    }
                                </div>
                            </> : <></>
                        }
                    </div>
                </form >

                <div
                    className="batch_column_mapa"
                    style={{ height: 550 }}
                >
                    <div
                        className={`area__drawarea--container`}
                        style={{
                            width: 400,
                            height: 300
                        }}
                    >
                        <LeafletStaticMap>
                            <FlyToBounds ref={flytoBoundsRef} fitCoords={fitCoords}>
                                {/* area */}
                                <Polygon
                                    positions={fitCoords}
                                    color={Constants.LEAFLET_UNFOCUS.color}
                                />
                                {/* microareas */}
                                {microAreaRoute &&
                                    microAreaRoute.map((micro: any) => (
                                        <Polygon
                                            positions={micro.map_coords || []}
                                            color={Constants.LEAFLET_UNFOCUS_TRANSLUCENT.color}
                                            fillColor={Constants.LEAFLET_NORMAL_BLUE.fillColor}
                                            fillOpacity={
                                                Constants.LEAFLET_UNFOCUS_TRANSLUCENT.fillOpacity
                                            }
                                        />
                                    ))
                                }
                            </FlyToBounds>
                        </LeafletStaticMap>
                    </div>
                </div>
            </div>


            <footer style={{ width: 300 }}>
                <ButtonOnlyTitle
                    title={"Salvar"}
                    theme="info"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={typeManagement?.value != 1 && (startDate === undefined || Object.keys(errors).length !== 0)}
                />
            </footer>
        </>
    )
}