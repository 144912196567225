import { CSSProperties, useEffect, useRef, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";

import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState,
} from "../../../../components/Leaflet/FlyToBounds/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { BatchesIcons, ICustomBatch } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { TextModal } from "../../../../components/Modal/Contents/TextModal/Index";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { useFilter } from "../../../../hooks/useFilter";

import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import Constants from "../../../../constants/Index";
import api from "../../../../services/axios";

import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";
import { IBatch } from "../../../../@types/API/IBatches";

import { normalizeToScale } from "../../../../utils/numbers/normalizeToPercent";
import { getColorFromValue } from "../../../../utils/colors/colorFromAScale";

import "./styles.css";
import { BatchesSpecsDetails } from "../../../../components/PaddocksMap/BatchesSpecsDetails";

type IEntityAreaORFarm = { map_coords: ICoords, id: number, name: string, initials: string }

const contentStyleAlert = {
  width: "440px",
  height: "218px",
  borderRadius: "20px",
  overflow: "hidden",
  zIndex: 999,
} as CSSProperties;

type CapacityViewProps = {
  view: "immediate" | "avarege"
}

export function PastureCapacity({ view }: CapacityViewProps) {
  const { farmFilter, areaFilter, defaultBGFarm, setHideMicroAreaFilter, setHideBatchFilter } = useFilter();

  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");
  const [farms, setFarms] = useState<IFarm[]>([]);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [microAreas, setMicroAreas] = useState<IMicroArea[]>([]);
  const [batchesIcons, setBatchesIcons] = useState<ICustomBatch[]>([]);
  const [errorModal, seterrorModal] = useState<boolean>(false);
  const [batchByMicroAreaMap] = useState(new Map<number, IBatch>());

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  const toggleAlert = () => seterrorModal((visible) => !visible);

  const getFarmsAllFarmsByUser = () => {
    api.get<IFarm[]>('/farms/user')
      .then(({ data: farms }) => {
        setFarms(farms)
        getAllBatchesByUser(farms)
      })
      .catch(toggleAlert)
  }

  const getFarmsAllAreasByUser = () => {
    api.get<IArea[]>('/areas/user')
      .then(({ data: areas }) => setAreas(areas))
      .catch(toggleAlert)
  }

  const getFarmsAllMicroAreasByUser = () => {
    api.get<IMicroArea[]>('/micro-areas')
      .then(({ data: microAreas }) => {
        setMicroAreas(microAreas)
      })
      .catch(toggleAlert)
  }

  const getAllBatchesByUser = (farms: IFarm[]) => {
    farms.map(farm => {
      api.get<IBatch[]>(`/batches`, { params: { farm_id: farm.id } })
        .then(({ data: batches }) => {
          const batchIcons = batches.map(e => {
            batchByMicroAreaMap.set(Number(e.current_micro_area.id), e)

            return {
              initials: e.initials,
              coords: getCenterCoords(e.current_micro_area.map_coords),
              id: e.id.toString(),
              areaId: e.area.id.toString(),
              visible: true
            } as ICustomBatch
          })
          setBatchesIcons(prev => [...prev, ...batchIcons])
        })
        .catch(toggleAlert)
    })
  }

  function handleBGOppacity(farm: IFarm, area: IEntityAreaORFarm) {
    if (areaFilter && area.id.toString() !== areaFilter.id.toString()) return 0.2;
    if (farm.id.toString() !== farmFilter.id.toString()) return 0.2;
    return 0.55;
  }

  const handleCurrentColor = (microArea: IMicroArea) => {
    const currentValueColor = normalizeToScale(0, 8, view == "avarege" ? microArea.average_lot_rate : microArea.instant_lot_rate)
    return getColorFromValue(currentValueColor, [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100], ["#FFFFFF", "#FFEAD8", "#FFD5B1", "#FFC08A", "#FFAB63", "#FF9501", "#FF7727", "#FF593E", "#FF3C58", "#FF1E79", "#FF0000"])
  }

  useEffect(() => {
    if (view == "avarege") {
      document.title = Constants.TITLE_PADDOCKS_MAP_CAPACITY_AVAREGE;
    } else {
      document.title = Constants.TITLE_PADDOCKS_MAP_CAPACITY_IMMEDIATE;
    }

    getFarmsAllFarmsByUser()
    getFarmsAllAreasByUser()
    getFarmsAllMicroAreasByUser()
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
  }, [, view])

  useEffect(() => {
    setBatchesIcons([])

    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }, [farmFilter])

  return (
    <div className="defaultmap__container">
      <AlertModal visible={errorModal} animation="slideUp" contentStyle={contentStyleAlert}>
        <section className="defaultmap__alert--content">
          <SimpleButtonClose onClick={toggleAlert} />
          <div className="defaultmap__alert--texts">
            <TitleModal>{Constants.MESSAGE_INVALID_MODAL_TITLE}</TitleModal>
            <TextModal>{Constants.MESSAGE_INVALID_MODAL_FILTER}</TextModal>
          </div>
          <ButtonOnlyTitle onClick={toggleAlert} title="voltar" theme="info" />
        </section>
      </AlertModal>

      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farms.map(farm => {
          return <AnimatedZoom key={farm.id} coordsToBoundZoom={farm.map_coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                key={farm.initials}
                position={getCenterCoords(farm.map_coords)}
                initials={farm.initials}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                key={farm.id}
                pathOptions={{ fillColor: defaultBGFarm }}
                positions={farm.map_coords}
                color={Constants.PADDOCKTIME_COLORS.WHITE}
                fillOpacity={0.55}
                fill={true}
                weight={2}
              >
                <Tooltip className="customToolTip__container" sticky>
                  <SpecsMouseOver microAreaLabel={`${farm.initials}`} type={"farm"} />
                </Tooltip>
              </Polygon>
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.map_coords} />
              {areas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((area) => (
                  <Polygon
                    key={area.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, area),
                      color: Constants.PADDOCKTIME_COLORS.WHITE,
                      fillColor: Constants.PADDOCKTIME_COLORS.ORANGE,
                    }}
                    color={Constants.PADDOCKTIME_COLORS.WHITE}
                    positions={area.map_coords}
                    fill={true}
                    weight={2}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        microAreaLabel={`${farm?.initials}-${area.initials}`}
                        type={"area"}
                      />
                    </Tooltip>
                  </Polygon>
                ))}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <PolylineFarm path={farm.map_coords} />
              <BatchesIcons batches={batchesIcons} />
              {microAreas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((microArea) => {
                  const batch = batchByMicroAreaMap.get(microArea.id)

                  return <Polygon
                    key={microArea.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, microArea.area),
                      color: Constants.PADDOCKTIME_COLORS.WHITE,
                      fillColor: handleCurrentColor(microArea),
                    }}
                    positions={microArea.map_coords}
                    fill={true}
                    weight={2}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        className={(microArea.average_lot_rate || microArea.instant_lot_rate) ? "havebatch" : ""}
                        microAreaLabel={`${farm?.initials}-${microArea.area.initials}-${microArea.initials}`}
                        type={"batch"}
                      />
                      {
                        (microArea.average_lot_rate || microArea.instant_lot_rate) ?
                          <BatchesSpecsDetails
                            batchLabel={batch?.initials ? `${farm.initials}-${microArea.area.initials}-${batch?.initials}` : undefined}
                            qtdLabel={batch?.animals_quantity ? `${batch?.animals_quantity} animais` : undefined}
                            capacity={`${view == "avarege" ? Number(microArea.average_lot_rate).toFixed(2) : Number(microArea.instant_lot_rate).toFixed(2)}`}
                          />
                          :
                          <></>
                      }
                    </Tooltip>
                  </Polygon>
                })}
            </ContainerZoom4X>
          </AnimatedZoom>
        })}

      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      <section className="capacitybvroute__barnumber--container">
        <strong className="capacitybvroute__barnumber--title">{view == "avarege" ? 'LOTAÇÃO MÉDIA (UA/HA)' : 'LOTAÇÃO INSTANTÂNEA (UA/HA)'}</strong>
        <div className="capacitybvroute__barnumber--bar" />
        <div className="capacitybvroute__barnumber--containerlegend">
          <span>0,00</span>
          <span>1,00</span>
          <span>2,00</span>
          <span>3,00</span>
          <span>4,00</span>
          <span>5,00</span>
          <span>6,00</span>
          <span>7,00</span>
          <span>8,00</span>
        </div>
      </section>
    </div>
  );
}
