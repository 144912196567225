import { useState } from "react";

import Logo from "../../../assets/png/logo.png";
import { BsWhatsapp } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { BiPlus } from "react-icons/bi";

import "./styles.css";

export function TalkWhatsApp() {
  const [channelWhatsApp, setChannelWhatsApp] = useState(false);

  return (
    <>
      <div className="whatsapp-talk-container">
        <button
          className={channelWhatsApp ? "show-button-close" : ""}
          onClick={() => {
            setChannelWhatsApp(!channelWhatsApp);
          }}
        >
          {channelWhatsApp ? <IoClose id="icon" /> : <BiPlus id="icon" />}
        </button>
        <div className="whatsapp-talk-content">
          <header
            onClick={() => {
              setChannelWhatsApp(!channelWhatsApp);
            }}
          >
            <BsWhatsapp />
            <strong>Olá, Fale com o Rodrigo</strong>
          </header>
          <main
            className={
              channelWhatsApp ? "whatsapp-talk-content-show" : "whatsapp-talk-content-hide"
            }
          >
            <div className="whatsapp-talk-main-content">
              <span>Vamos nos falar por WhatsApp?</span>
              <div className="profile-pastejo">
                <div className="avatar">
                  <img src={Logo} alt="logo pastejo" />
                </div>
                <div className="profile">
                  <span>Pastejo</span>
                  <h5>Atendimento Online</h5>
                  <span>online</span>
                </div>
              </div>
              <div className="divider"></div>
              <div className="lets-talk">
                <a href="https://wa.me/553498034512" className="liskstart">
                  começar
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
