import { ICoord } from "../../../@types/GoogleMaps/ICoord";
import { generateuuid } from "../../../utils/uuid/generate";
import { CustomPopup } from "../../Leaflet/CustomPopup/Index";

import { ReactComponent as CattleIcon } from "../../../assets/svg/icons/animais.svg";

type IBatchIconProps = {
  coords: ICoord;
  background?: string;
};

export function BatchIcon({ coords, background = "#FFF" }: IBatchIconProps) {
  return (
    <CustomPopup key={generateuuid()} position={coords} offset={[0, 40]}>
      <div
        className="labelbatches__container"
        style={{
          background: background,
          border: "3px solid #FFF",
        }}
      >
        <CattleIcon fill={"#4D4D4D"} />
      </div>
    </CustomPopup>
  );
}
