import { CSSProperties, useEffect, useRef, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import "./styles.css";

import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState,
} from "../../../../components/Leaflet/FlyToBounds/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import { LegendRounded } from "../../../../components/PaddocksMap/LegendRounded/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { BatchesIcons, ICustomBatch } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { TextModal } from "../../../../components/Modal/Contents/TextModal/Index";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { useFilter } from "../../../../hooks/useFilter";

import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import Constants from "../../../../constants/Index";
import api from "../../../../services/axios";

import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";
import { IBatch } from "../../../../@types/API/IBatches";
import { formatDateToCustomFormat } from "../../../../utils/date/formatedDate";

import { normalizeToScale } from "../../../../utils/numbers/normalizeToPercent";
import { getColorFromValue } from "../../../../utils/colors/colorFromAScale";
import { BatchIcon } from "../../../../components/PaddocksMap/BatchIcon/Index";

type IFarmData = {
  address: {
    state: string
    street: string
  },
  document: string,
  id: number,
  initials: string,
  map_coords: ICoords
  name: string
  owner_user: { id: number, name: string }
  properties: { season: { dry_initial: number, rain_initial: number } }
  state_registration: string
}

type IAreaData = {
  manager_user: { id: number, name: string },
  map_coords: ICoords,
  indoor_grazing_time: number,
  outdoor_grazing_time: number,
  id: number,
  name: string,
  initials: string,
  acreage: number,
  farm: {
    map_coords: ICoords,
    id: number,
    name: string,
    initials: string
  }
}

type IEntityAreaORFarm = { map_coords: ICoords, id: number, name: string, initials: string }

const contentStyleAlert = {
  width: "440px",
  height: "218px",
  borderRadius: "20px",
  overflow: "hidden",
  zIndex: 999,
} as CSSProperties;

interface PastureManagementProps {
  filter: "all" | "rate" | "height" | "day"
}

export function PastureManagement({ filter }: PastureManagementProps) {
  const { farmFilter, areaFilter, defaultBGFarm } = useFilter();

  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");
  const [farms, setFarms] = useState<IFarmData[]>([]);
  const [areas, setAreas] = useState<IAreaData[]>([]);
  const [microAreas, setMicroAreas] = useState<IMicroArea[]>([]);
  const [batchesIcons, setBatchesIcons] = useState<ICustomBatch[]>([]);
  const [errorModal, seterrorModal] = useState<boolean>(false);
  const [maxValueOnGradiente, setMaxValueOnGradiente] = useState(0);
  const [maxDescanso, setMaxDescanso] = useState(0);
  const [maxOcupacao, setMaxOcupacao] = useState(0);

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  const toggleAlert = () => seterrorModal((visible) => !visible);

  const getFarmsAllFarmsByUser = () => {
    api.get<IFarmData[]>('/farms/user')
      .then(({ data: farms }) => {
        setFarms(farms)
        getAllBatchesByUser()
      })
      .catch(toggleAlert)
  }

  const getFarmsAllAreasByUser = () => {
    api.get<IAreaData[]>('/areas/user')
      .then(({ data: areas }) => setAreas(areas))
      .catch(toggleAlert)
  }

  const getAllBatchesByUser = () => {
    api.get<IBatch[]>(`/batches`, { params: { farm_id: farmFilter.id } })
      .then(({ data: batches }) => {
        const batchIcons = batches.map(e => {

          return {
            initials: e.initials,
            coords: getCenterCoords(e.current_micro_area.map_coords),
            id: e.id.toString(),
            areaId: e.area.id.toString(),
            visible: true,
            currentMicroArea: Number(e.current_micro_area.id)
          } as ICustomBatch
        })

        setBatchesIcons(batchIcons)
      })
      .catch(toggleAlert)
  }

  const getFarmsAllMicroAreasByUser = () => {
    api.get<IMicroArea[]>('/micro-areas')
      .then(({ data: microAreas }) => {
        var maxValue = 0

        setMicroAreas(microAreas);

        const monitoringTypeMap = {
          rate: 1,
          height: 2,
        };

        if (filter in monitoringTypeMap) {
          const monitoringTypeId = monitoringTypeMap[filter as keyof typeof monitoringTypeMap];
          maxValue = Math.max(
            ...microAreas
              .filter(
                (ma) =>
                  ma.management.type.monitoring_types.length > 0 &&
                  ma.management.type.monitoring_types.find(el => el.id == monitoringTypeId)
              )
              .map((el) => el.monitory_values)
              .flat()
              .filter((el) => el && el.monitoring_type_id.id === monitoringTypeId)
              .map((el) => el.value),
            0
          );

          setMaxValueOnGradiente(maxValue)
        } else {
          const grazingMaxValue = Math.max(
            ...microAreas
              .filter(
                (ma) =>
                  ma.management.type.monitoring_types.length > 0 &&
                  ma.management.type.monitoring_types.find(el => el.id == 3)
              )
              .map((el) => el.management.requirement_in)
          );

          const withoutGrazingMaxValue = Math.max(
            ...microAreas
              .filter(
                (ma) =>
                  ma.management.type.monitoring_types.length > 0 &&
                  ma.management.type.monitoring_types.find(el => el.id == 3)
              )
              .map((el) => el.management.requirement_out)
          );

          setMaxOcupacao(grazingMaxValue)
          setMaxDescanso(withoutGrazingMaxValue)
        }

      })
      .catch(toggleAlert)
  }

  const handleBGOppacity = (farm: IFarmData, area: IEntityAreaORFarm) => {
    if (areaFilter && area.id.toString() !== areaFilter.id.toString()) return 0.2;
    if (farm.id.toString() !== farmFilter.id.toString()) return 0.2;
    return 0.55;
  }

  const handleCurrentColor = (microArea: IMicroArea) => {
    if (
      microArea.management.type.monitoring_types && microArea.management.type.monitoring_types.length > 0
    ) {
      if (filter == "all") {
        switch (microArea.management.type.id) {
          case 1: // Sem monitoramento
            return Constants.PASTUREMANAGEMENT_COLORS.WHITE
          case 2: // Contínuo
            return Constants.PASTUREMANAGEMENT_COLORS.ORANGE
          case 3: // Rotacionado
            return Constants.PASTUREMANAGEMENT_COLORS.RED
          case 4: // Em faixa
            return Constants.PASTUREMANAGEMENT_COLORS.BLUE
        }
      }

      else if (filter == "rate" && microArea.management.type.monitoring_types.find(el => el.id == 1)) {
        if (
          microArea.monitory_values &&
          microArea.monitory_values.length > 0 &&
          microArea.monitory_values.find(el => el.monitoring_type_id.id == 1)
        ) {
          const currentGrazingTime = maxValueOnGradiente

          const currentValueColor = normalizeToScale(0, currentGrazingTime, microArea.monitory_values.find(el => el.monitoring_type_id.id == 1)?.value || 0)
          return getColorFromValue(currentValueColor, [0, 100], ["#FF6161", "#57CE71"])
        }
        return Constants.PASTUREMANAGEMENT_COLORS.YELLOW
      }

      else if (filter == "height" && microArea.management.type.monitoring_types.find(el => el.id == 2)) {
        if (
          microArea.monitory_values &&
          microArea.monitory_values.length > 0 &&
          microArea.monitory_values.find(el => el.monitoring_type_id.id == 2)
        ) {
          const currentGrazingTime = maxValueOnGradiente

          const currentValueColor = normalizeToScale(0, currentGrazingTime, microArea.monitory_values.find(el => el.monitoring_type_id.id == 2)?.value || 0)
          return getColorFromValue(currentValueColor, [0, 100], ["#FF6161", "#57CE71"])
        }
        return Constants.PASTUREMANAGEMENT_COLORS.YELLOW
      }

      else if (filter == "day" && microArea.management.type.monitoring_types.find(el => el.id == 3)) {
        const { days_grazing, days_without_grazing } = microArea

        const currentGrazingTime = days_grazing == 0 ? maxDescanso : maxOcupacao

        const currentValueColor = normalizeToScale(0, currentGrazingTime, days_grazing != 0 ? days_grazing : currentGrazingTime - days_without_grazing)
        if (days_grazing == 0)
          return getColorFromValue(currentValueColor, [0, 50, 100], ["#7B61FF", "#61FFF6", "#57CE71"])
        else
          return getColorFromValue(currentValueColor, [0, 50, 100], ["#FFCA80", "#FF9501", "#FF6161"])
      }
    }
  }

  useEffect(() => {
    document.title = Constants.TITLE_PADDOCKS_MAP_PASTURE_MANAGEMENT;

    getFarmsAllFarmsByUser()
    getFarmsAllAreasByUser()
    getFarmsAllMicroAreasByUser()
  }, [, farmFilter, filter])

  useEffect(() => {
    setBatchesIcons([])

    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }, [farmFilter])

  useEffect(() => {
    setMaxValueOnGradiente(0)
  }, [filter]);

  const handleCurrentColorBatch = (batch: ICustomBatch) => {
    const microArea = microAreas.find((el) => el.id === batch.currentMicroArea)
    if (
      microArea &&
      microArea.management.type.monitoring_types && microArea.management.type.monitoring_types.length > 0
    ) {
      if (filter == "rate" && microArea.management.type.monitoring_types.find(el => el.id == 1)) {
        if (microArea.monitory_values && microArea.monitory_values.length > 0) {
          const lastValue = microArea.monitory_values
            .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
            .find(el => el.monitoring_type_id.id == 1)

          if (
            microArea.monitory_values &&
            microArea.monitory_values.length > 0 &&
            lastValue
          ) {
            if (lastValue.value > microArea.management.requirement_in)
              return Constants.PASTUREMANAGEMENT_COLORS.GREEN
            else if ((lastValue.value < microArea.management.requirement_in) && (lastValue.value > microArea.management.requirement_out))
              return Constants.PASTUREMANAGEMENT_COLORS.CYAN
            return Constants.PASTUREMANAGEMENT_COLORS.RED
          }
        }
        else return Constants.PASTUREMANAGEMENT_COLORS.YELLOW
      }

      else if (filter == "height" && microArea.management.type.monitoring_types.find(el => el.id == 2)) {
        const lastValue = microArea.monitory_values
          .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
          .find(el => el.monitoring_type_id.id == 2)

        if (
          microArea.monitory_values &&
          microArea.monitory_values.length > 0 &&
          lastValue
        ) {
          if (lastValue.value > microArea.management.requirement_in)
            return Constants.PASTUREMANAGEMENT_COLORS.GREEN
          else if ((lastValue.value < microArea.management.requirement_in) && (lastValue.value > microArea.management.requirement_out))
            return Constants.PASTUREMANAGEMENT_COLORS.CYAN
          return Constants.PASTUREMANAGEMENT_COLORS.RED
        }
        else return Constants.PASTUREMANAGEMENT_COLORS.YELLOW
      }

      else if (filter == "day" && microArea.management.type.monitoring_types.find(el => el.id == 3)) {
        if (microArea.days_grazing > 0) {
          if (microArea.days_grazing > microArea.management.requirement_in)
            return Constants.PASTUREMANAGEMENT_COLORS.RED
          else if ((microArea.days_grazing <= microArea.management.requirement_in))
            return Constants.PASTUREMANAGEMENT_COLORS.GREEN
        }
        else if (microArea.days_without_grazing > 0) {
          if (microArea.days_without_grazing > microArea.management.requirement_out)
            return Constants.PASTUREMANAGEMENT_COLORS.RED
          else if ((microArea.days_without_grazing <= microArea.management.requirement_out))
            return Constants.PASTUREMANAGEMENT_COLORS.GREEN
        }
      }
    }
  }

  return (
    <div className="defaultmap__container">
      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farms.map(farm => {
          return <AnimatedZoom key={farm.id} coordsToBoundZoom={farm.map_coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                key={farm.initials}
                position={getCenterCoords(farm.map_coords)}
                initials={farm.initials}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                key={farm.id}
                pathOptions={{ fillColor: defaultBGFarm }}
                positions={farm.map_coords}
                color={Constants.PASTUREMANAGEMENT_COLORS.WHITE}
                fillOpacity={0.55}
                fill={true}
                weight={2}
                noClip
              />
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.map_coords} />
              {areas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((area) => (
                  <Polygon
                    key={area.id}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(farm, area),
                      color: Constants.PASTUREMANAGEMENT_COLORS.WHITE,
                      fillColor: Constants.PASTUREMANAGEMENT_COLORS.ORANGE,
                    }}
                    color={Constants.PASTUREMANAGEMENT_COLORS.WHITE}
                    positions={area.map_coords}
                    fill={true}
                    weight={2}
                    noClip
                  />
                ))}
            </ContainerZoom3X>

            <ContainerZoom4X>
              {batchesIcons
                .filter((f) => f.visible)
                .map((batch, key) => (
                  <BatchIcon key={key} coords={batch.coords} background={handleCurrentColorBatch(batch)} />
                ))}
              <PolylineFarm path={farm.map_coords} />
              {microAreas
                .filter((microArea) => microArea.farm.id === farm.id)
                .map((microArea) => <Polygon
                  key={microArea.id}
                  pathOptions={{
                    fillOpacity: handleBGOppacity(farm, microArea.area),
                    color: Constants.PASTUREMANAGEMENT_COLORS.WHITE,
                    fillColor: handleCurrentColor(microArea),
                  }}
                  positions={microArea.map_coords}
                  fill={true}
                  weight={2}
                  noClip
                >
                  <Tooltip className="customToolTip__container" sticky>
                    <SpecsMouseOver
                      className={
                        (
                          (microArea.management.type.monitoring_types && microArea.management.type.monitoring_types.length > 0) ?
                            (filter == "all") ||
                            (filter == "rate" && microArea.management.type.monitoring_types.find(el => el.id == 1)) ||
                            (filter == "height" && microArea.management.type.monitoring_types.find(el => el.id == 2)) ||
                            (filter == "day" && microArea.management.type.monitoring_types.find(el => el.id == 3))
                            : false
                        ) ? "havebatch" : ""
                      }
                      microAreaLabel={`${microArea?.farm?.initials}-${microArea.area.initials}-${microArea.initials}`}
                      type={"batch"}
                    />
                    {microArea.management.type.id != 1 && microArea.management &&
                      (
                        filter == "all" ||
                        (filter == "rate" && microArea.management.type.monitoring_types.find(el => el.id == 1)) ||
                        (filter == "height" && microArea.management.type.monitoring_types.find(el => el.id == 2)) ||
                        (filter == "day" && microArea.management.type.monitoring_types.find(el => el.id == 3))
                      ) &&
                      <div className="infoSpecsDetails__container">
                        {/* Exibição em submenu tipo de manejo */}
                        {filter == "all" &&
                          <h3 className="qtdDetails">
                            {microArea.management.type.monitoring_types[0].name}
                          </h3>
                        }
                        {/* Exibição em submenu nota */}
                        {filter === "rate" && microArea.management.type.monitoring_types.some(type => type.id === 1) && (
                          <div>
                            <h3 className="qtdDetails">
                              Nota de entrada: {microArea?.management?.requirement_in}
                              <br />
                              Nota de saída: {microArea?.management?.requirement_out}
                              <div className="delimiter" style={{ marginTop: 10, marginBottom: 10 }} />
                              {microArea?.monitory_values?.length > 0 ? (
                                (() => {
                                  const monitoringValue = microArea.monitory_values.find(value => value.monitoring_type_id.id === 1);
                                  return monitoringValue ? (
                                    <>
                                      Última nota coletada: <strong>{monitoringValue.value}</strong>
                                      <br />
                                      {formatDateToCustomFormat(
                                        new Date(monitoringValue.updated_at || monitoringValue.created_at)
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      Nota atual
                                      <br />
                                      não coletada
                                    </>
                                  );
                                })()
                              ) : (
                                <>
                                  Nota atual
                                  <br />
                                  não coletada
                                </>
                              )}
                            </h3>
                          </div>
                        )}

                        {/* Exibição em submenu altura */}
                        {filter === "height" && microArea.management.type.monitoring_types.some(type => type.id === 2) && (
                          <div>
                            <h3 className="qtdDetails">
                              Altura de entrada:  {microArea?.management?.requirement_in}
                              <br />
                              Altura de saída: {microArea?.management?.requirement_out}
                              <div className="delimiter" style={{ marginTop: 10, marginBottom: 10 }} />
                              {microArea?.monitory_values?.length > 0 ? (
                                (() => {
                                  const monitoringValue = microArea.monitory_values.find(value => value.monitoring_type_id.id === 2);
                                  return monitoringValue ? (
                                    <>
                                      Última altura coletada: <strong>{monitoringValue.value}</strong>
                                      <br />
                                      {formatDateToCustomFormat(
                                        new Date(monitoringValue.updated_at || monitoringValue.created_at)
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      Altura atual
                                      <br />
                                      não coletada
                                    </>
                                  );
                                })()
                              ) : (
                                <>
                                  Altura atual
                                  <br />
                                  não coletada
                                </>
                              )}
                            </h3>
                          </div>
                        )}

                        {/* Exibição em submenu dias */}
                        {filter === "day" && microArea.management.type.monitoring_types.some(type => type.id === 3) && (
                          <div>
                            <h3 className="qtdDetails">
                              Dias de ocupação: {microArea?.management?.requirement_in}
                              <br />
                              Dias de descanso: {microArea?.management?.requirement_out}
                              <div className="delimiter" style={{ marginTop: 10, marginBottom: 10 }} />
                              {microArea.days_grazing != 0 ?
                                <div>
                                  {microArea.days_grazing} dias
                                  <br />
                                  de ocupação
                                </div>
                                :
                                <div>
                                  {microArea.days_without_grazing} dias
                                  <br />
                                  de descanso
                                </div>
                              }

                            </h3>
                          </div>
                        )}
                      </div>
                    }
                  </Tooltip>
                </Polygon>
                )}
            </ContainerZoom4X>
          </AnimatedZoom>
        })}

      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      {
        filter == "all" ? <section className="pasturemanagement__footerlegend--container">
          <h6>Manejo</h6>
          <div className="pasturemanagement__footerlegend--content">
            <LegendRounded label="Contínuo" bgColor={Constants.PASTUREMANAGEMENT_COLORS.ORANGE} />
            <LegendRounded label="Rotacionado" bgColor={Constants.PASTUREMANAGEMENT_COLORS.RED} />
            <LegendRounded label="Em faixa" bgColor={Constants.PASTUREMANAGEMENT_COLORS.BLUE} />
            <LegendRounded label="Sem monitoramento" bgColor={Constants.PASTUREMANAGEMENT_COLORS.WHITE} />
          </div>
        </section> : <></>
      }
      {
        filter == "height" ? <section className="pasturemanagement__footerlegend--container">
          {maxValueOnGradiente != 0 &&
            <>
              <h6>Altura</h6>
              <div className="pasturemanagement__footerlegend--content">
                <div>
                  <div className="pasturemanagement--bar" />
                  <div className="pasturemanagement--containerlegend">
                    <span>0</span>
                    <span>{maxValueOnGradiente}</span>
                  </div>
                </div>
              </div>
              <br />
            </>
          }
          <div className="pasturemanagement__footerlegend--content">
            <LegendRounded label="Acima da altura" bgColor={Constants.PASTUREMANAGEMENT_COLORS.GREEN} />
            <LegendRounded label="Dentro do intervalo" bgColor={Constants.PASTUREMANAGEMENT_COLORS.CYAN} />
            <LegendRounded label="Abaixo da altura" bgColor={Constants.PASTUREMANAGEMENT_COLORS.RED} />
            <LegendRounded label="Sem altura atribuida" bgColor={Constants.PASTUREMANAGEMENT_COLORS.YELLOW} />
          </div>
        </section> : <></>
      }
      {
        filter == "rate" ? <section className="pasturemanagement__footerlegend--container">
          {maxValueOnGradiente != 0 &&
            <>
              <h6>Nota</h6>
              <div className="pasturemanagement__footerlegend--content">
                <div>
                  <div className="pasturemanagement--bar" />
                  <div className="pasturemanagement--containerlegend">
                    <span>0</span>
                    <span>{maxValueOnGradiente}</span>
                  </div>
                </div>
              </div>
              <br />
            </>
          }
          <div className="pasturemanagement__footerlegend--content">
            <LegendRounded label="Acima da nota" bgColor={Constants.PASTUREMANAGEMENT_COLORS.GREEN} />
            <LegendRounded label="Dentro do intervalo" bgColor={Constants.PASTUREMANAGEMENT_COLORS.CYAN} />
            <LegendRounded label="Abaixo da nota" bgColor={Constants.PASTUREMANAGEMENT_COLORS.RED} />
            <LegendRounded label="Sem nota atribuida" bgColor={Constants.PASTUREMANAGEMENT_COLORS.YELLOW} />
          </div>
        </section> : <></>
      }
      {
        filter == "day" ?
          <section className="pasturemanagement__footerlegend--container">
            {maxOcupacao != 0 &&
              <div>
                <strong className="capacitybvroute__barnumber--title">Dias de ocupação</strong>
                <div className="paddocktime__barnumber--bar1" />
                <div className="paddocktime__barnumber--containerlegend">
                  <span>0</span>
                  <span>{maxOcupacao}</span>
                </div>
              </div>
            }
            <br />
            {maxDescanso != 0 &&
              <div>
                <strong className="capacitybvroute__barnumber--title">Dias de descanso</strong>
                <div className="paddocktime__barnumber--bar2" />
                <div className="paddocktime__barnumber--containerlegend">
                  <span>0</span>
                  <span>{maxDescanso}</span>
                </div>
              </div>
            }
            <br />
            <div className="pasturemanagement__footerlegend--content">
              <LegendRounded label="Acima dos dias" bgColor={Constants.PASTUREMANAGEMENT_COLORS.RED} />
              <LegendRounded label="Abaixo dos dias" bgColor={Constants.PASTUREMANAGEMENT_COLORS.GREEN} />
            </div>
          </section> : <></>
      }
    </div>
  );
}
