import { FilterDateDashboard } from "../../../../components/Dashboard/ViewDatas/FilterDate";
import { ReactComponent as BagFood } from "../../../../assets/svg/icons/bagFood.svg";
import { DateModal } from "../../../../components/Modal/DateFilter";
import { useFilter } from "../../../../hooks/useFilter";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios"
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { IOptionProps } from "../../../../@types/reactSelect/IOption";

export default function DashboardSupplementationProductReport() {
  const defaultValues = {
    id: 0,
    supplement_classification_id: 0,
    supplement_classification: "-",
    trade_name: "-",
    total_consumption_period: 0,
    analyzed_period: 0,
    supplemented_animals: 0,
    average_weight_of_supplemented_animals: 0,
    total_weight_of_supplemented_animals: 0,
    uas_total_number_of_animals_supplemented: 0,
    average_consumption_in_the_period_kg: 0,
    average_consumption_in_the_period_kg_pct: 0,
    average_consumption_in_the_period_ua: 0,
    average_consumption_in_the_period_ua_pct: 0,
    replacement_frequency: 0,
    replacement_frequency_pct: 0
  }

  const {
    farmFilter,
    areasIdsFilter,
    rangeDays,
    setRangeDays,
    setHideMicroAreaFilter,
    setHideBatchFilter,
    setDashboard,
    setSideBarOptionSelected
  } = useFilter();

  const [allAreas, setAllAreas] = useState<IOptionProps[]>([]);

  useEffect(() => {
    if (farmFilter && areasIdsFilter.length == 0) {
      axios.get("/areas", {
        params: {
          farm_id: farmFilter.id
        }
      }).then((response) => {
        const areas = response.data.map((item: IArea) => ({
          label: item.name,
          value: item.id
        }))
        setAllAreas(areas)
      })
    }
  }, [, farmFilter]);

  useEffect(() => {
    const now = new Date();
    const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const firstDayOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

    setRangeDays({
      from: firstDayOfLastMonth,
      to: firstDayOfCurrentMonth
    })
  }, [allAreas]);

  useEffect(() => {
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
    setSideBarOptionSelected("DASHBOARD")
    setDashboard("SUPPLEMENTATION")
    document.title = Constants.TITLE_DASHBOARD;
  }, []);

  const IconMarq = (value: number, red: number, green: number) => {
    let hex = "FFFFFF";

    if (green > red) {
      if (value < red) {
        hex = "FF0000";
      } else if (value <= green) {
        hex = "FFFF00";
      } else {
        hex = "00FF00";
      }
    } else {
      if (value > red) {
        hex = "FF0000";
      } else if (value >= green) {
        hex = "FFFF00";
      } else {
        hex = "00FF00";
      }
    }

    return (
      <div style={{
        width: 20,
        height: 20,
        background: `#${hex}`,
        borderRadius: 50,
        border: "1px solid #FFF"
      }} />
    )
  }

  const LegendaTable = (title: string, green: number, red: number) => {

    return (
      <div className="tableDashboard" id={"estoque_suple"}>
        <table>
          <thead style={{
            color: "#fff"
          }}>
            <th scope="col" rowSpan={2} style={{
              background: "#324440",
              width: "180px",
              textAlign: "left"
            }}>
              {title}
            </th>

            {[{
              legenda: `>${red}%`
            },
            {
              legenda: `>=${red} <=${green}%`
            },
            {
              legenda: `<${green}%`
            }].map((item, index) => (
              <th scope="col" rowSpan={2} style={{
                background: "#324440",
                width: "150px",
                textAlign: "left"
              }}>
                {item.legenda}
              </th>
            ))}
          </thead>

          <tbody>
            <tr className="bg_white">
              <th scope="row"></th>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(1, 30, 50)}</td>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(35, 30, 50)}</td>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(55, 30, 50)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const [analysisProduct, setAnalysisProduct] = useState<ISupplementAnalysisProduct[]>([defaultValues]);
  const [analysisBatch, setAnalysisBatch] = useState<ISupplementAnalysisProduct[]>([]);

  useEffect(() => {
    if (farmFilter?.id && (rangeDays || areasIdsFilter.length > 0 || allAreas.length > 0)) {

      let startDate = format(rangeDays?.from ? new Date(rangeDays?.from) : new Date(), 'yyyy-MM-dd HH:mm:ss')
      let endDate = format(rangeDays?.to ? new Date(rangeDays?.to) : new Date(), 'yyyy-MM-dd HH:mm:ss')

      const params = {
        farmId: farmFilter.id,
        areaIds: areasIdsFilter.length > 0 ? areasIdsFilter.map((item) => item.value) : allAreas.map((item) => item.value),
        startDate: startDate,
        endDate: endDate
      }
      axios.get("/supplement/analysis-by-product", {
        params,
        paramsSerializer: params => {
          return new URLSearchParams(params).toString();
        }
      }).then((response) => {
        if (response.data.length > 0)
          setAnalysisProduct(response.data)
      })
      axios.get("/supplement/analysis-batch-by-product", {
        params,
        paramsSerializer: params => {
          return new URLSearchParams(params).toString();
        }
      }).then((response) => {
        if (response.data.length > 0)
          setAnalysisBatch(response.data)
      })
    }
  }, [rangeDays, areasIdsFilter, allAreas]);

  const tableProduct = (products: ISupplementAnalysisProduct[]) => {
    const haveBatch = products.some((item) => item.batch_initials);

    const renderTableHeader = () => (
      <>
        {haveBatch ?
          <>
            <br />
            <h2>Consumo por lote</h2>
          </> : <></>}

        <thead style={{ color: "#fff" }}>
          <tr>
            <th scope="col" style={{ background: "#808080", width: 150, textAlign: "left" }} hidden={!haveBatch}>Lote</th>
            <th scope="col" style={{ background: "#324440", width: haveBatch ? 230 : 380, textAlign: haveBatch ? "center" : "left" }}>Nome do Produto</th>
            {[
              "Classificação do Produto",
              "Consumo Total no Período (Kg)",
              "Período Analisado (dias)",
              "Animais Suplementados",
              "Peso Médio dos Animais Suplementados (Kg)",
              "Peso Total dos Animais Suplementados (Kg)",
              "Consumo Médio no Período (g/kgPV.dia)",
              "Consumo Médio no Período (g.UA/dia)",
              "Frequência de Reposição (Média de Dias)"
            ].map((title, index) => (
              <th key={index} scope="col" style={{
                background: index % 2 === 0 ? "#808080" : "#324440",
                padding: "10px 20px",
                width: 150
              }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
      </>
    );

    const renderTableBody = () => (
      <tbody>
        {products.map((item, index) => (
          <tr key={index} className={index % 2 === 0 ? "bg_white" : "bg_gray"}>
            <th hidden={!haveBatch}>{item.batch_initials}</th>
            <th>{item.trade_name}</th>
            <td>{item.supplement_classification}</td>
            <td>{item.total_consumption_period}</td>
            <td>{item.analyzed_period}</td>
            <td>{item.supplemented_animals}</td>
            <td>{item.average_weight_of_supplemented_animals}</td>
            <td>{item.total_weight_of_supplemented_animals}</td>
            <td>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                {IconMarq(item.average_consumption_in_the_period_kg_pct, 15, 5)}
                {item.average_consumption_in_the_period_kg}
              </div>
            </td>
            <td>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                {IconMarq(item.average_consumption_in_the_period_ua_pct, 15, 5)}
                {item.average_consumption_in_the_period_ua}
              </div>
            </td>
            <td>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                {IconMarq(item.replacement_frequency_pct, 30, 15)}
                {item.replacement_frequency}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    );

    return (
      <div className="tableDashboard">
        <table>
          {renderTableHeader()}
          {renderTableBody()}
        </table>
      </div>
    );
  }

  return (
    <div className="cattleMap_background">
      <DateModal selectOnlyMonth />

      <div className="cattleMap_page">
        <div className="cattleMap_header">
          <BagFood height={72} />
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            <h2 className="cattleMap_title">
              Suplementação
            </h2>
            <h3 className="cattleMap_subtitle">
              Análise de consumo
            </h3>
          </div>
        </div>

        <FilterDateDashboard />

        {
          <div className="tableDashboard" id={"estoque_suple"}>
            {tableProduct(analysisProduct)}

            {analysisBatch.length != 0 ? tableProduct(analysisBatch) : <></>}
            <br />
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 10
            }}>
              {LegendaTable("Legenda Consumo", 5, 15)}

              {LegendaTable("Legenda Frequência", 15, 30)}
            </div>
          </div>
        }
      </div>
    </div>
  );
}
